import React from "react"
import { Link } from "gatsby"
import configuration from "../../configuration"
import { Category } from "../entities/Category"
import { Obfuscated } from "./Obfuscated"

const socials = []

export const TinyNavbar: React.FC<{
  categories: Category[]
  obfuscation?: boolean
}> = props => {
  return (
    <>
      <div className="relative w-full bg-black">
        <div className="relative z-10 max-w-6xl mx-auto lg:px-0">
          <div className="relative flex w-full h-14">
            <div className="flex items-center h-full px-1 overflow-auto text-gray-300">
              <Link
                className="flex-shrink-0 px-3 text-lg font-bold text-white hover:text-white"
                to="/"
              >
                {configuration.manifest.short_name}
              </Link>

              {props.categories.map(({ title, id, url }) => (
                <Obfuscated
                  should={props.obfuscation}
                  className="flex-shrink-0 px-3 hover:text-white"
                  key={id}
                  to={url}
                >
                  {title}
                </Obfuscated>
              ))}
            </div>
            <div className="absolute right-0 w-10 h-full pointer-events-none lg:hidden bg-gradient-to-r from-transparent to-black"></div>
            <div className="items-center hidden h-full pr-4 ml-auto space-x-4 text-gray-300 lg:flex">
              {socials.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  className=" hover:text-white"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
