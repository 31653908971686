import React from "react"
import { Link, PageProps } from "gatsby"
import { Footer } from "../components/Footer"
import { Category } from "../entities/Category"
import { Container } from "../components/Container"
import { TinyNavbar } from "../components/TinyNavbar"
import Layout from "../components/Layout"
import configuration from "../configuration/404.json"
import { Seo } from "../components/Seo"

const Privacy: React.FC<
  PageProps<
    any,
    {
      categories: Category[]
    }
  >
> = props => {
  const { categories } = props.pageContext

  return (
    <Layout>
      <Seo
        title={configuration.seo.title}
        description={configuration.seo.description}
        url={props.location.href}
      />

      <TinyNavbar categories={categories} obfuscation />

      <Container className="py-20 mt-10 text-center md:py-40">
        <div className="font-bold text-9xl">404</div>
        <div className="mt-2 text-4xl font-light text-gray-500">
          La page n'existe pas
        </div>
        <div className="mt-4">
          <Link to="/" className="text-black hover:underline">
            Cliquez ici pour revenir à la page principale &rarr;
          </Link>
        </div>
      </Container>

      <Footer categories={categories} obfuscation />
    </Layout>
  )
}

export default Privacy
